import axios from '@/axios.js'


export default {
    getVersionList: async (parameters) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/versions`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteVersion: async (uuid) => {
        try {
            const response = await axios.editorapi.delete(`/v4/app-configurator/versions/${uuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    checkStatusVersion: async (uuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/versions/${uuid}/status`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    cloneVersion: async (uuid, newUuid, parameters = {}) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/versions/${newUuid}/?q=from:${uuid}`, parameters)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    createVersion: async (uuid, parameters) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/versions/${uuid}`, parameters)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editVersion: async (uuid, parameters) => {
        try {
            const response = await axios.editorapi.patch(`/v4/app-configurator/versions/${uuid}`, parameters)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    getVersion: async (uuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/versions/${uuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    releaseVersion: async (uuid) => {
        try {
            const response = await axios.editorapi.patch(`/v4/app-configurator/versions/${uuid}/release`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
}
