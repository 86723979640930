var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row w-full bg-white p-5 rounded-md m-1" },
    [
      _vm.action === "update"
        ? _c("div", { staticClass: "pt-5 pb-10 w-full md:w-5/6 2xl:w-7/12" }, [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.resources-title"))
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.resources-subtitle"))
              )
            ]),
            _c(
              "div",
              { staticClass: "buttons mt-10" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      color: "primary",
                      type: "filled rounded-lg w-50 mr-10"
                    },
                    on: {
                      click: function($event) {
                        return _vm.goToViews()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("apps.configurator.resources.view-gallery"))
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "mb-10 mt-5 w-full" }, [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.$t("apps.configurator.resources.upload-resources-title"))
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass:
            "border rounded-lg mb-10 p-8 static-resources-uploader-container w-full md:w-5/6 2xl:w-7/12"
        },
        [
          _c("div", { staticClass: "mb-7" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.static-header"))
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.static-subtitle"))
              )
            ])
          ]),
          _c("ResourceInput", {
            attrs: {
              id: _vm.static_id,
              name: _vm.static_title,
              showError: _vm.error_static_png
            },
            on: { updateFile: _vm.handleUpdateFile }
          }),
          _c(
            "div",
            { staticClass: "buttons mt-10" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled rounded-lg w-50 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.processStaticResource()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("apps.configurator.resources.upload-files"))
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "border rounded-lg p-8 static-resources-uploader-container w-full md:w-5/6 2xl:w-7/12"
        },
        [
          _c("div", { staticClass: "mb-7" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.dynamic-header"))
              )
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.resources.dynamic-subtitle"))
              )
            ])
          ]),
          _c("ResourceInput", {
            attrs: {
              id: _vm.dynamic_winter_id,
              name: _vm.dynamic_winter,
              showError: _vm.error_dynamic_winter
            },
            on: { updateFile: _vm.handleUpdateFile }
          }),
          _c("ResourceInput", {
            attrs: {
              id: _vm.dynamic_summer_id,
              name: _vm.dynamic_summer,
              showError: _vm.error_dynamic_summer
            },
            on: { updateFile: _vm.handleUpdateFile }
          }),
          _c("ResourceInput", {
            attrs: {
              id: _vm.dynamic_fonts_id,
              name: _vm.dynamic_fonts,
              showError: _vm.error_dynamic_fonts
            },
            on: { updateFile: _vm.handleUpdateFile }
          }),
          _c(
            "div",
            { staticClass: "buttons mt-10" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled rounded-lg w-50 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.processDynamicResource()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("apps.configurator.resources.upload-files"))
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.action === "update"
        ? _c(
            "div",
            { staticClass: "pt-10 w-full md:w-5/6 2xl:w-7/12 rounded-lg" },
            [
              _c("div", { staticClass: "mb-10" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "apps.configurator.resources.download-resources-title"
                      )
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "border rounded-lg p-8" }, [
                _vm.resources.dynamic["Android"] !== ""
                  ? _c("div", { staticClass: "flex flex-column mt-5" }, [
                      _c("h4", { staticClass: "mb-5" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apps.configurator.resources.static-header")
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.static["Android"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.static["iOS"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("IOS")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.resources.dynamic["Android-hdpi"] !== ""
                  ? _c("div", { staticClass: "flex flex-column mt-5" }, [
                      _c("h4", { staticClass: "mb-5" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apps.configurator.resources.dynamic-header")
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["Android-hdpi"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android-hdpi")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["Android-mdpi"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android-mdpi")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["Android-xhdpi"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android-xhdpi")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["Android-xxhdpi"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android-xxhdpi")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["Android-xxxhdpi"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("Android-xxxhdpi")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["iOS-2x"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("IOS-2x")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-10 mb-3 flex flex-row items-center"
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses: "align-middle w-6 text-primary"
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "pl-2",
                              attrs: {
                                href: _vm.resources.dynamic["iOS-3x"],
                                target: "_blank"
                              }
                            },
                            [_vm._v("IOS-3x")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _c("ModalInfo", {
        attrs: {
          modalOpen: _vm.modalOpen,
          type: _vm.modalType,
          headingText: _vm.headingModalText,
          text: _vm.modalText
        },
        on: { closedModal: _vm.closedModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }