<template>
    <div class="vx-row w-full bg-white p-5 rounded-md m-1">
        <div v-if="action === 'update'" class="pt-5 pb-10 w-full md:w-5/6 2xl:w-7/12">
            <h3>{{ $t("apps.configurator.resources.resources-title") }}</h3>
            <span>{{ $t("apps.configurator.resources.resources-subtitle") }}</span>
            <div class="buttons mt-10">
                <vs-button color="primary" type="filled rounded-lg w-50 mr-10" @click="goToViews()">{{ $t("apps.configurator.resources.view-gallery") }}</vs-button>
            </div>
        </div>
        <div class="mb-10 mt-5 w-full">
            <h3>{{ $t("apps.configurator.resources.upload-resources-title") }}</h3>
        </div>
        <div class="border rounded-lg mb-10 p-8 static-resources-uploader-container w-full md:w-5/6 2xl:w-7/12">
            <div class="mb-7">
                <h4>{{ $t("apps.configurator.resources.static-header") }}</h4>
                <span>{{ $t("apps.configurator.resources.static-subtitle") }}</span>
            </div>
            <ResourceInput
                :id="static_id"
                :name="static_title"
                @updateFile="handleUpdateFile"
                :showError="error_static_png"
            />
            <div class="buttons mt-10">
                <vs-button color="primary" type="filled rounded-lg w-50 mr-10" @click="processStaticResource()">{{ $t("apps.configurator.resources.upload-files") }}</vs-button>
            </div>
        </div>
        <div class="border rounded-lg p-8 static-resources-uploader-container w-full md:w-5/6 2xl:w-7/12">
            <div class="mb-7">
                <h4>{{ $t("apps.configurator.resources.dynamic-header") }}</h4>
                <span>{{ $t("apps.configurator.resources.dynamic-subtitle") }}</span>
            </div>
            <ResourceInput
                :id="dynamic_winter_id"
                :name="dynamic_winter"
                @updateFile="handleUpdateFile"
                :showError="error_dynamic_winter"
            />
            <ResourceInput
                :id="dynamic_summer_id"
                :name="dynamic_summer"
                @updateFile="handleUpdateFile"
                :showError="error_dynamic_summer"
            />
            <ResourceInput
                :id="dynamic_fonts_id"
                :name="dynamic_fonts"
                @updateFile="handleUpdateFile"
                :showError="error_dynamic_fonts"
            />
            <div class="buttons mt-10">
                <vs-button color="primary" type="filled rounded-lg w-50 mr-10" @click="processDynamicResource()">{{ $t("apps.configurator.resources.upload-files") }}</vs-button>
            </div>
        </div>
        <div v-if="action === 'update'" class="pt-10 w-full md:w-5/6 2xl:w-7/12 rounded-lg">
            <div class="mb-10">
                <h3>{{ $t("apps.configurator.resources.download-resources-title") }}</h3>
            </div>
            <div class="border rounded-lg p-8">
                <div v-if="resources.dynamic['Android'] !== ''" class="flex flex-column mt-5">
                    <h4 class="mb-5">{{ $t("apps.configurator.resources.static-header") }}</h4>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.static['Android']" target="_blank" class="pl-2">Android</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.static['iOS']" target="_blank" class="pl-2">IOS</a>
                    </div>
                </div>
                <div v-if="resources.dynamic['Android-hdpi'] !== ''" class="flex flex-column mt-5">
                    <h4 class="mb-5">{{ $t("apps.configurator.resources.dynamic-header") }}</h4>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['Android-hdpi']" target="_blank" class="pl-2">Android-hdpi</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['Android-mdpi']" target="_blank" class="pl-2">Android-mdpi</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['Android-xhdpi']" target="_blank" class="pl-2">Android-xhdpi</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['Android-xxhdpi']" target="_blank" class="pl-2">Android-xxhdpi</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['Android-xxxhdpi']" target="_blank" class="pl-2">Android-xxxhdpi</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['iOS-2x']" target="_blank" class="pl-2">IOS-2x</a>
                    </div>
                    <div class="pl-10 mb-3 flex flex-row items-center">
                        <feather-icon icon="DownloadIcon" svgClasses="align-middle w-6 text-primary"/>
                        <a :href="resources.dynamic['iOS-3x']" target="_blank" class="pl-2">IOS-3x</a>
                    </div>
                </div>
            </div>
        </div>
        <ModalInfo
            :modalOpen=modalOpen
            :type=modalType
            :headingText=headingModalText
            :text=modalText
            @closedModal="closedModal"
        />
    </div>
</template>

<script>
import ResourceInput from '@/modules/Apps/Components/resources/ResourceInput'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import ResourceService from '@/modules/Apps/Services/Configurator/ConfiguratorResourcesService'
import ModalInfo from '@/modules/Shared/Components/modal/ModalInfo'
import { v4 as uuidv4 } from 'uuid';
import ConfiguratorVersionService from "@/modules/Apps/Services/Configurator/ConfiguratorVersionService";
import store from "@/modules/Shared/Store/store";


export default {
    components: {
        ResourceInput,
        ModalInfo
    },
    data() {
        return {
            action: "",
            resources: [],
            modalOpen: false,
            modalType: "",
            headingModalText: null,
            modalText: "",
            static_title: this.$t("apps.configurator.resources.static-assets"),
            dynamic_winter: this.$t("winter"),
            dynamic_summer: this.$t("summer"),
            dynamic_fonts: this.$t("apps.configurator.resources.dynamic-fonts-assets"),
            static_id: "static_id",
            dynamic_winter_id: "dynamic_winter_id",
            dynamic_summer_id: "dynamic_summer_id",
            dynamic_fonts_id: "dynamic_fonts_id",
            static_file: null,
            dynamic_winter_file: null,
            dynamic_summer_file: null,
            dynamic_fonts_file: null,
            error_static: false,
            error_dynamic_winter: false,
            error_dynamic_summer: false,
            error_dynamic_fonts: false,
        }
    },
    async beforeRouteEnter (to, from, next) {
        const version = await ConfiguratorVersionService.getVersion(to.params.versionUuid);
        await store.dispatch('setRouteElement', version)
        next()
    },
    async created() {
        this.getResources()
    },
    methods: {
        async getResources() {
            let params = {
                    page: 1,
                    filters: [{ field: "version_uuid", value: this.$route.params.versionUuid }]
                }
            let response =  await ResourceService.getResources(PaginationService.parseParameters(params))

            if(response.data.length === 0) {
                this.action = "create"
            }else {
                this.action = "update"
            }

            this.resources = response.data[0]
        },
        handleUpdateFile(fileObject) {
            if (fileObject.id === this.static_id) {
                this.static_file = fileObject.file
            } else if (fileObject.id === this.dynamic_winter_id) {
                this.dynamic_winter_file = fileObject.file
            } else if (fileObject.id === this.dynamic_summer_id) {
                this.dynamic_summer_file = fileObject.file
            } else if (fileObject.id === this.dynamic_fonts_id) {
                this.dynamic_fonts_file = fileObject.file
            }
        },
        async processStaticResource() {

            this.error_static = false

            const staticFiles = [
                { key: "static", value: this.static_file },
            ];

            const emptyFiles = staticFiles.filter(emptyFile => emptyFile.value === null);
            if (emptyFiles.length > 0) {
                emptyFiles.forEach(_ => {
                    this.error_static = true;
                })
            } else {
                this.$vs.loading()
                const body = {
                    version_uuid: this.$route.params.versionUuid,
                    type: 'static',
                    static: await this.getPresignedS3Url(this.static_file),
                }
                await this.createOrEditResoruces(body, this.$t("apps.configurator.resources.static-fields-text-heading"))
            }
        },
        async processDynamicResource() {

            this.error_dynamic_winter = false;
            this.error_dynamic_summer = false;
            this.error_dynamic_fonts = false;

            this.$vs.loading()
            let body = { version_uuid: this.$route.params.versionUuid }
            if(this.dynamic_winter_file) {
                body.dynamic_winter = await this.getPresignedS3Url(this.dynamic_winter_file)
            }
            if(this.dynamic_summer_file) {
                body.dynamic_summer = await this.getPresignedS3Url(this.dynamic_summer_file)
            }
            if(this.dynamic_fonts_file) {
                body.fonts = await this.getPresignedS3Url(this.dynamic_fonts_file)
            }

            await this.createOrEditDynamicResources(body)
        },
        async getPresignedS3Url(file) {
            const binaryAsset = new Blob([file]);

            let name = `${Date.now()}__${file.name}`
            let presignedUrl = await ResourceService.getTempAssetResource(name)
            presignedUrl = presignedUrl.url
            await ResourceService.uploadFileInS3Resource(presignedUrl, binaryAsset)

            return presignedUrl
        },
        async createOrEditResoruces(body, type) {
            try {
                let response
                if(this.action === "create") {
                    response = await ResourceService.createResources(uuidv4(), body)
                } else {
                    response = await ResourceService.updateResources(this.resources.uuid, body)
                }
                this.$vs.loading.close()
                if (response.status_code == 201 || response.status_code == 200) {
                    this.modalType = "success"
                    this.modalOpen = true
                    this.modalText = this.$t("apps.configurator.resources.upload-ok")
                    this.headingModalText = type
                } else {
                    this.modalType = "warning"
                    this.modalOpen = true
                    this.modalText = this.$t("apps.configurator.resources.upload-ko")
                    this.headingModalText = type
                }
            } catch (error) {
                this.$vs.loading.close()
                this.modalType = "danger"
                this.modalText = this.$t("apps.configurator.resources.upload-ko-wrong-files")
                this.modalOpen = true
                this.headingModalText = type
            }
            await this.getResources()
        },
        async createOrEditDynamicResources(body) {
            try {
                let resourceUuid
                if(this.action === "create") {
                    resourceUuid = uuidv4();
                }else {
                    resourceUuid = this.resources.uuid
                }

                await ResourceService.updateDynamicResources(resourceUuid, body)

                this.$vs.loading.close()
                this.modalType = "success"
                this.modalOpen = true
                this.modalText = this.$t("apps.configurator.resources.upload-ok")
            } catch (error) {
                const data = error.response.data
                this.$vs.loading.close()
                this.modalType = "danger"
                this.modalText = Array.isArray(data.description)
                    ? data.description.join('\n')
                    : data.description
                this.modalOpen = true
            }
            await this.getResources()
        },
        closedModal() {
            this.modalOpen = false
        },
        goToViews() {
            this.$router.push({name: 'gallery-resources', params: {"uuid": this.$route.params.uuid, "versionUuid": this.$route.params.versionUuid}})
        }
    }
}
</script>
