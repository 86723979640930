<template>
    <div class="resource-input-container w-auto my-5">
        <div class="resource-input-title-container w-auto">
            <div class="resource-input-title my-3">
                <b class="capitalize">{{ name }}</b>
            </div>
        </div>
        <div class="resource-input-inputs-container w-auto">
            <div class="resource-input-text flex flex-row">
                <vs-button :disabled=true :class="{ 'border-danger': showError }" type="border" class="w-3/4 text-left button-select-image inline-block rounded-lg mr-2">{{ placeholderText }}</vs-button>
                <vs-button :disabled=false type="border" class="w-1/4 vs-component vs-button vs-button-primary vs-button-border rounded-lg mr-3" @click="handlerFiles()">{{ $t("apps.configurator.resources.select-file") }}</vs-button>
            </div>
            <b-alert :show="showError" variant="danger" class="my-5 rounded-lg">{{ $t("apps.configurator.resources.required-select-file") }}</b-alert>
        </div>
        <vs-input
            ref="input-file"
            type="file"
            :name="name"
            hidden
            :id="id"
            @change="onFileChange"
            :danger="validateError"
        />
    </div>
</template>

<script>
export default {
    name: 'resource-input',
    props: {
        'id': String,
        'name': String,
        'showError': Boolean
    },
    computed: {},
    components: {},
    data() {
        return {
            validateError: false,
            placeholderText: this.$t("apps.configurator.resources.resource-input-placeholder")
        }
    },
    created() {},
    methods: {
        handlerFiles() {
            this.$refs['input-file'].$refs.vsinput.click()
        },
        onFileChange(event) {
            const file = event.target.files[0]
            this.placeholderText = file.name
            this.$emit('updateFile', {file: file, id: this.id})
        }
    }
}
</script>