var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-input-container w-auto my-5" },
    [
      _c("div", { staticClass: "resource-input-title-container w-auto" }, [
        _c("div", { staticClass: "resource-input-title my-3" }, [
          _c("b", { staticClass: "capitalize" }, [_vm._v(_vm._s(_vm.name))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "resource-input-inputs-container w-auto" },
        [
          _c(
            "div",
            { staticClass: "resource-input-text flex flex-row" },
            [
              _c(
                "vs-button",
                {
                  staticClass:
                    "w-3/4 text-left button-select-image inline-block rounded-lg mr-2",
                  class: { "border-danger": _vm.showError },
                  attrs: { disabled: true, type: "border" }
                },
                [_vm._v(_vm._s(_vm.placeholderText))]
              ),
              _c(
                "vs-button",
                {
                  staticClass:
                    "w-1/4 vs-component vs-button vs-button-primary vs-button-border rounded-lg mr-3",
                  attrs: { disabled: false, type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.handlerFiles()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("apps.configurator.resources.select-file"))
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "my-5 rounded-lg",
              attrs: { show: _vm.showError, variant: "danger" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("apps.configurator.resources.required-select-file")
                )
              )
            ]
          )
        ],
        1
      ),
      _c("vs-input", {
        ref: "input-file",
        attrs: {
          type: "file",
          name: _vm.name,
          hidden: "",
          id: _vm.id,
          danger: _vm.validateError
        },
        on: { change: _vm.onFileChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }